<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="Service Charge"
          :breadcrumb="[
            {
              label: 'Services',
            },
            { label: 'Services' },
          ]"
        >
        </banner>

        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div
              class="d-xl-none d-md-none d-block pt-1"
              style="padding: 5px 5px 5px 5px"
            ></div>
            <div class="card custom-card">
              <div class="card-body">
                <div class="row">
                  <div class="col text font">
                    <h4> <strong style="color:#00364f"> Service Charge</strong></h4>
                    <!-- <strong>Service Charge</strong> -->
                  </div>
                  <!-- <div class="col text-end ">
                    <button type="button" @click="loadFormNull()" data-bs-toggle="modal"
                      data-bs-target="#masterDistributerModal" title="Add New" style="background-color: #f21000"
                      class="btn text-white btn-sm">+ New</button>
                    &nbsp;<button type="button" data-bs-toggle="modal" data-bs-target="#new-task-modal" title="Filter"
                      class="btn text-white btn-sm" style="background-color: #00364f"> <strong> <font-awesome-icon
                          icon="filter" /></strong></button>

                  </div> -->
                    <div class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1" style="margin-top:-5px">

                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>



                <div class="row">
                  <div class="d-md-none d-xl-none d-block col-3 text font">

                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
                    <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>

                    </select>
                  </div>
               
                </div>
                <!-- start list area  -->
                <div class="row">



                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" >
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm text">
                        <tr class="tr-head table-wrapper-scroll-y rounded-circle">
                          <th>Code</th>
                          <th class="text-truncate">Service Name</th>
                          <th class="text-truncate">Service Charge</th>
                          <th class="text-truncate">Admin Fees</th>
                          <th>Package</th>
                          <th class="text-truncate" v-if="portal == 'false'">
                            Master Distributer
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </th>
                          <th v-if="portal == 'false'">
                            Distributer&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </th>
                          <th v-if="portal == 'false'">
                            Retailer&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </th>
                          <th>Status</th>
                          <th class="text-end" v-if="portal == 'false'">Action</th>
                        </tr>

                        <tbody>
                          <tr
                            style="color: #00364f"
                            v-for="(admService, index) in adminServices"
                            :key="index"
                          >
                            <td class="text-truncate" v-if="admService.service">
                              {{ admService.service.code }}
                            </td>
                            <td class="text-truncate" v-if="admService.service">
                              {{ admService.service.name }}
                            </td>
                            <td class="text-truncate" v-if="admService.service">
                              {{ admService.serviceCharge }}
                            </td>
                            <td class="text-truncate" v-if="admService.service">
                              {{ admService.service.adminFee }}
                            </td>
                            <td class="text-truncate">
                              <span v-if="admService.service">
                                <span v-if="admService.service.package_service">
                                  {{ admService.service.package_service.package.package }}
                                </span>
                              </span>
                            </td>

                            <td class="text-truncate" v-if="portal == 'false'">
                              <div class="input-group">
                                <input
                                  type="text"
                                  :value="admService.masterDistributerFee"
                                  class="form-control"
                                  placeholder="Percentage"
                                  style="height: 30px"
                                  disabled
                                />
                                <span
                                  style="height: 30px"
                                  class="input-group-text"
                                  id="basic-addon1"
                                  ><strong>%</strong></span
                                >
                              </div>
                            </td>

                            <td class="text-truncate" v-if="portal == 'false'">
                              <div class="input-group">
                                <input
                                  type="text"
                                  :value="admService.distributerFee"
                                  class="form-control"
                                  placeholder="Percentage"
                                  style="height: 30px"
                                  disabled
                                />
                                <span
                                  style="height: 30px"
                                  class="input-group-text"
                                  id="basic-addon1"
                                  ><strong>%</strong></span
                                >
                              </div>
                            </td>

                            <td class="text-truncate" v-if="portal == 'false'">
                              <div class="input-group text-truncate">
                                <input
                                  type="text"
                                  :value="admService.retailerFee"
                                  class="form-control"
                                  placeholder="Percentage"
                                  style="height: 30px"
                                  disabled
                                />
                                <span
                                  style="height: 30px"
                                  class="input-group-text"
                                  id="basic-addon1"
                                  ><strong>%</strong></span
                                >
                              </div>
                            </td>
                            <td class="text-truncate" v-if="admService.service">
                              <p
                                v-if="admService.service.status == 'true'"
                                class="text-white btn btn-sm"
                                style="
                                  background-color: green;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> Active</b>
                              </p>
                              <p
                                v-else
                                class="text-white btn btn-sm"
                                style="
                                  background-color: #f21300;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> InActive</b>
                              </p>
                            </td>
                            <td class="text-end text-truncate" v-if="portal == 'false'">
                              <button
                                type="button"
                                @click="editServiceCharges(admService)"
                                data-bs-toggle="modal"
                                data-bs-target="#updatecharges"
                                class="btn btns text-white btn-sm"
                                style="
                                  padding: 5px 4.5px 5px 4.5px;
                                  background-color: green;
                                "
                              >
                                <font-awesome-icon icon="edit" />
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
                    <div class="card-footer">
                <div class="row">

                  <div class="col-md-4 col-12 text-md-start text-center cus-p-1">

                    <p class="mb-0">
                      Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                    </p>
                  </div>
                  <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                    <ul class="pagination  justify-content-center">
                      <li class="page-item" @click="getServices(null, 'first')">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item" @click="getServices(null, 'prev')">
                        <a class="page-link" href="#">Previous</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 3"
                        @click="getServices(pagination.current_page - 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 3
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 2"
                        @click="getServices(pagination.current_page - 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 1"
                        @click="getServices(pagination.current_page - 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 1
                        }}</a>
                      </li>
                      <li class="active page-item" @click="getServices(pagination.current_page)">
                        <a class="page-link" href="#">{{ pagination.current_page }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 4"
                        @click="getServices(pagination.current_page + 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 1
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 5"
                        @click="getServices(pagination.current_page + 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 6"
                        @click="getServices(pagination.current_page + 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 3
                        }}</a>
                      </li>
                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="getServices( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                      <li class="page-item">
                        <a class="page-link" href="#">...</a>
                      </li>

                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="getServices( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 3"
                        @click="getServices(pagination.last_page - 3)">
                        <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 2"
                        @click="getServices(pagination.last_page - 2)">
                        <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 1"
                        @click="getServices(pagination.last_page - 1)">
                        <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                      </li>

                      <li class="page-item" @click="getServices(null, 'next')">
                        <a class="page-link" href="#">Next</a>
                      </li>
                      <li class="page-item" @click="getServices(null, 'last')">
                        <a class="page-link" href="#">&raquo;</a>
                      </li>
                    </ul>
                  </div>

                  <!-- <div class="col-md-1 col-4 text-end">
      <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
    </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <!-- Modal -->
    <div
      class="modal fade"
      id="updatecharges"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header" style="background-color: #00364f">
            <h5 class="modal-title text-white" id="exampleModalLabel">
              <strong>Update Charges</strong>
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-12" v-if="msgError">
                <div class="alert alert-danger p-1" role="alert">
                  <small> {{ msgError }}</small>
                </div>
              </div>
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-12"
                style="padding: 5px 5px 5px 5px"
                v-if="portal == 'false'"
              >
                <label
                  ><strong style="color: #00364f">Master Distributer Fee</strong></label
                >
                <div class="input-group">
                  <input
                    type="number"
                    v-model="admin_services_charge_form.masterDistributerFee"
                    class="form-control"
                    placeholder="Percentage"
                    style="height: 30px"
                  />
                  <span style="height: 30px" class="input-group-text" id="basic-addon1"
                    ><strong>%</strong></span
                  >
                  <div>
                    <small style="color: red" v-if="admin_services_charge_form.errors"
                      ><span v-if="admin_services_charge_form.errors.error"
                        ><span
                          v-if="
                            admin_services_charge_form.errors.error.masterDistributerFee
                          "
                          >{{
                            admin_services_charge_form.errors.error
                              .masterDistributerFee[0]
                          }}</span
                        ></span
                      ></small
                    >
                  </div>
                </div>
              </div>
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-12"
                style="padding: 5px 5px 5px 5px"
                v-if="portal == 'false'"
              >
                <label><strong style="color: #00364f">Distributer Fee</strong></label>
                <div class="input-group">
                  <input
                    type="number"
                    v-model="admin_services_charge_form.distributerFee"
                    class="form-control"
                    placeholder="Percentage"
                    style="height: 30px"
                  />
                  <span style="height: 30px" class="input-group-text" id="basic-addon1"
                    ><strong>%</strong></span
                  >
                  <div>
                    <small style="color: red" v-if="admin_services_charge_form.errors"
                      ><span v-if="admin_services_charge_form.errors.error"
                        ><span
                          v-if="admin_services_charge_form.errors.error.distributerFee"
                          >{{
                            admin_services_charge_form.errors.error.distributerFee[0]
                          }}</span
                        ></span
                      ></small
                    >
                  </div>
                </div>
              </div>
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label><strong style="color: #00364f">Retailer Fee</strong></label>
                <div class="input-group">
                  <input
                    type="number"
                    v-model="admin_services_charge_form.retailerFee"
                    class="form-control"
                    placeholder="Percentage"
                    style="height: 30px"
                  />
                  <span style="height: 30px" class="input-group-text" id="basic-addon1"
                    ><strong>%</strong></span
                  >
                  <div>
                    <small style="color: red" v-if="admin_services_charge_form.errors"
                      ><span v-if="admin_services_charge_form.errors.error"
                        ><span
                          v-if="admin_services_charge_form.errors.error.retailerFee"
                          >{{
                            admin_services_charge_form.errors.error.retailerFee[0]
                          }}</span
                        ></span
                      ></small
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm text-white"
              style="background-color: #f21000"
              data-bs-dismiss="modal"
            >
              <strong>Close</strong>
            </button>
            <button
              @click="saveServiceChargesAdmin"
              type="button"
              class="btn btn-sm text-white"
              style="background-color: #00364f"
            >
              <strong>Confirm</strong>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>
</template>

//
<script>
import Banner from "../../../components/admin/comman/Banner.vue";

import Spinner from "../../../components/admin/comman/Spinner.vue";
// import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "AdminSerfvices",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 10,

      packages: [],
      portal: "",
      msgError: "",
      loading: false,
      adminServices: [],
      edit: "false",
      admin_services_charge_form: {
        retailerFee: null,
        distributerFee: null,
        masterDistributerFee: null,
        errors: {},
      },
    };
  },
  methods: {
    changePageNo(e) {
      this.per_page = e.target.value;
      this.getServices();
    },
    editServiceCharges(admService) {
      this.admin_services_charge_form = admService;
      this.msgError = "";
    },
    saveServiceChargesAdmin() {
      var val1 = this.admin_services_charge_form.masterDistributerFee;
      var val2 = this.admin_services_charge_form.distributerFee;
      var val3 = this.admin_services_charge_form.retailerFee;

      var res = parseInt(val1) + parseInt(val2) + parseInt(val3);
      console.log(res);
      if (res <= 100 && res > 0) {
        this.$axios
          .put(
            `admin/adminservice/${this.admin_services_charge_form.id}`,
            this.admin_services_charge_form,
            {
              headers: { Authorization: "Bearer " + localStorage.accessToken },
            }
          )
          .then((res) => {
            console.log(res.data.data.data);
            this.adminServices = res.data.data.data;
            $("#updatecharges").modal("hide");
            this.getServices();
          })
          .catch((error) => {
            this.admin_services_charge_form.errors = error.response.data;
          });
      } else {
        this.msgError =
          "Sum Of Master Distributer %, Distributer % and Retailer % less then 100% And Grater Then  0% Must ";
      }
    },
    // masterDistributerFeeCharge(event) {
    //   this.admin_services_charge_form.masterDistributerFee = event.target.value;
    //   console.log(this.admin_services_charge_form);
    // },
    // distributerFeeCharge(event) {
    //   this.admin_services_charge_form.distributerFee = event.target.value;
    //   console.log(this.admin_services_charge_form);
    // },
    // retailerFeeCharge(event) {
    // this.adminServices[index].retailerFee = event.target.value;
    // alert(this.admin_services_charge_form.serviceCharge);
    //   this.admin_services_charge_form.retailerFee = event.target.value;
    //   console.log(this.admin_services_charge_form);
    // },
    loadRetailerPackages() {
      this.$axios
        .get("admin/packageservice?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data);
          this.packages = res.data.data;
        });
    },
    getServices(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
        pageUrl += `admin/adminservice?per_page=${this.per_page}`;
      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.adminServices = res.data.data.data;
          this.pagination = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.adminPageTitles.serviceCharge
    console.log(this.$store.state.adminPageTitles.serviceCharge)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.getServices();
      this.loadPageTitle()
      this.portal = localStorage.getItem("portal");
      
    }
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.steps-link {
  transition: all ease-in-out 0.2s;
  cursor: pointer;
}

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}
.steps-link:hover {
  border: 1px solid #888;
  background-color: #ddd;
}

.modal-bg {
  background-image: url(/assets/image/bg.jpg);
  background-size: cover;
  height: 100%;
  /* background-color: #f21300; */
}

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.gtsApp {
  padding: 5px 8px 5px 8px;
  margin-top: 3px;
  margin-bottom: 3px;
  border-radius: 100%;
  box-shadow: 2px 2px 5px gray;
  font-size: 8pt;
}

.form-check-input:focus {
  border-color: #00364f;
  outline: 0;
  box-shadow: 0px 2px 4px 0 #00364f3a;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}

.form-check-input[data-v-742849ee]:checked {
  background-color: #f8f8f8;
  border-color: #e74646;
  border-width: 3px;
}

.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}



.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 0px 10px 0px 10px;
}
</style>
